var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"10","elevation":_vm.$vuetify.theme.dark ? 9 : 8,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"button-flex button-type-1"},[_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.lgAndUp ? '' : 'px-0',attrs:{"text":"","min-height":"60","min-width":"60"},on:{"mouseover":function($event){return _vm.hoverItem()},"mouseleave":function($event){return _vm.unhoverItem()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"grey-2-hover-orange",attrs:{"size":"30","aria-label":'Activity'}},[_vm._v(_vm._s(_vm.icons.mdiBriefcase))]),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-icon',{staticClass:"grey-2-hover-orange"},[_vm._v(_vm._s(_vm.icons.mdiChevronDown))]):_vm._e()],1),_c('div',[_c('v-divider',{staticClass:"main-divider divider-hover-orange",style:({ visibility: _vm.isHovered || _vm.isOpen ? 'visible' : 'hidden' })})],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',{attrs:{"id":"app-bar-activity-content-container","width":"440px"}},[_c('div',{staticClass:"d-flex align-center justify-space-between flex-grow-1 px-7 py-8"},[_c('v-row',{attrs:{"align":"center"}},[_vm._l((_vm.tabs),function(tab,index){return [(tab.perms.every(perm => _vm.perms.indexOf(perm) > -1))?_c('v-col',{key:tab.id},[_c('label',{staticClass:"font-size-13",class:_vm.listTabStyles[index],on:{"click":function($event){return _vm.updateTab(index)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])]):_vm._e()]})],2)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.contentListTable[_vm.tab],"options":_vm.options,"loading":_vm.loading,"loading-text":_vm.t('DataTable.LoadingText'),"no-data-text":_vm.t('DataTable.NoDataText'),"no-results-text":_vm.t('DataTable.NoResultsText'),"footer-props":{
        'items-per-page-text': '',
        'items-per-page-options': [],
        'page-text': _vm.t('DataTable.FooterProps.PageText'),
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"pl-5",staticStyle:{"min-width":"250px","width":"250px"}},[_c('router-link',{staticClass:"font-weight-semibold",attrs:{"to":{
                  name: item.markedObjectId?.maintenanceFolderId != null ? 'project-document-view' : 
                        item.maintenancePlan?.maintenancePlanId != null ? 'maintenance-plan-railway-view' : 
                        item.catalogId != null ? 'catalog-view': 
                        'document-view',
                  params: {
                    id: item.documentLink?.id != null 
                        ? item.documentLink.id
                        : item.objectId.id,
                    folderId: item.markedObjectId?.maintenanceFolderId != null
                      ? item.markedObjectId.maintenanceFolderId
                      : null
                  }
                }}},[_vm._v(_vm._s(item.title)+_vm._s(item.extension === 'xml' || item.extension === '' ? '' : '.' + item.extension))])],1),_c('td',{staticClass:"pr-0",staticStyle:{"display":"flex","align-items":"center"},attrs:{"align":"center"}},[_c('label',{staticClass:"font-size-10",class:_vm.baseTextStyle},[_vm._v(" "+_vm._s(item.dateTranslated)+" ")])]),_c('td',{staticClass:"pa-0",attrs:{"align":"center"}},[(_vm.hasOptions(item))?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"action-menu"},[_c('v-btn',_vm._g(_vm._b({class:_vm.baseMenuStyle,staticStyle:{"display":"flex","width":"fit-content"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{on:{"click":function($event){if (item.extension === 'xml' && item.locked && item.objectId.version === 0) {
                        _vm.redirectTo({
                          name: 'editor',
                          params: { id: item.objectId.id },
                        })
                      } else {
                        _vm.redirectTo({
                          name: 'edit-document-view',
                          params: { id: item.objectId.id },
                        })
                      }}}},[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.t('Edit')))])])],1),(_vm.isTransferEditionTab && _vm.canTransferEdition([item]))?_c('v-list-item',{on:{"click":function($event){_vm.selectedDocuments = [item]
                          _vm.isTransferEditionDialogVisible = true;}}},[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.t('LIB.DM.TransferEdition')))])])],1):_vm._e()],1)],1):_vm._e()],1)])}),0)]}}])})],1),_c('transfer-edition-dialog',{attrs:{"is-dialog-visible":_vm.isTransferEditionDialogVisible,"items":_vm.selectedDocuments},on:{"get-transfer-edition-data":function($event){return _vm.getTransferEditionData($event)},"close-dialog":function($event){_vm.isTransferEditionDialogVisible = false},"transfer-edition":function($event){return _vm.transferEdition(_vm.selectedDocuments, $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }