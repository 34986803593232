<template>
  <v-fade-transition mode="out-in">
    <div class="button-flex button-type-1">
      <v-icon
        :key="isDark"
        @click="isDark = !isDark; unhoverItem();"
        class="ma-3 grey-2-hover-orange"
        @mouseover="hoverItem()"
        @mouseleave="unhoverItem()"
        size="30"
        :aria-label="'Theme'"
      >
        {{ isDark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
      </v-icon>
    </div>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'

import { ref, computed } from 'vue'

export default {
  setup() {
    const { isDark } = useAppConfig()

    const isHovered = ref(false)

    function hoverItem() {
      isHovered.value = true
    }

    function unhoverItem() {
      isHovered.value = false
    }

    return {
      isDark,
      hoverItem,
      unhoverItem,

      // Icons
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
}
</script>

<style>
</style>
