<template>
  <v-menu
    offset-y
    nudge-bottom="10"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    v-model="isOpen"
    :close-on-content-click="false"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div class="button-flex button-type-1">
        <v-btn
          v-bind="attrs"
          class="d-flex align-center"
          :class="$vuetify.breakpoint.lgAndUp ? '' : 'px-0'"
          v-on="on"
          text
          min-height="60"
          min-width="60"
          @mouseover="hoverItem()"
          @mouseleave="unhoverItem()"
        >
          <v-icon class="grey-2-hover-orange" size="30" :aria-label="'Activity'">{{ icons.mdiBriefcase }}</v-icon>
          <!--<span class="pl-2 pr-1 font-size-14 font-weight-regular" v-if="$vuetify.breakpoint.lgAndUp">{{
            t('Activity')
          }}</span>-->
          <!-- :style="{'--textColor': textColor}" -->
          <v-icon class="grey-2-hover-orange" v-if="$vuetify.breakpoint.lgAndUp">{{ icons.mdiChevronDown }}</v-icon>
        </v-btn>
        <div>
          <v-divider
            class="main-divider divider-hover-orange"
            :style="{ visibility: isHovered || isOpen ? 'visible' : 'hidden' }"
          />
        </div>
      </div>
    </template>

    <!-- Options List -->
    <v-card id="app-bar-activity-content-container" width="440px">
      <div class="d-flex align-center justify-space-between flex-grow-1 px-7 py-8">
        <v-row align="center">
          <template v-for="(tab, index) in tabs">
            <v-col :key="tab.id" v-if="tab.perms.every(perm => perms.indexOf(perm) > -1)">
              <label class="font-size-13" :class="listTabStyles[index]" @click="updateTab(index)">
                {{ tab.title }}
              </label>
            </v-col>
          </template>
          <!--<v-col cols="3" align="right">
            <v-icon class="mx-2">
              {{ icons.mdiFilterVariant }}
            </v-icon>
            <label class="font-weight-semibold main-color">{{ t('Filter') }}</label>
          </v-col>-->
        </v-row>
      </div>
      <v-divider />
      <v-data-table
        :headers="tableColumns"
        :items="contentListTable[tab]"
        :options.sync="options"
        :loading="loading"
        :loading-text="t('DataTable.LoadingText')"
        :no-data-text="t('DataTable.NoDataText')"
        :no-results-text="t('DataTable.NoResultsText')"
        :footer-props="{
          'items-per-page-text': '',
          'items-per-page-options': [],
          'page-text': t('DataTable.FooterProps.PageText'),
        }"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td style="min-width: 250px; width: 250px;" class="pl-5">
                <router-link
                  class="font-weight-semibold"
                  :to="{
                    name: item.markedObjectId?.maintenanceFolderId != null ? 'project-document-view' : 
                          item.maintenancePlan?.maintenancePlanId != null ? 'maintenance-plan-railway-view' : 
                          item.catalogId != null ? 'catalog-view': 
                          'document-view',
                    params: {
                      id: item.documentLink?.id != null 
                          ? item.documentLink.id
                          : item.objectId.id,
                      folderId: item.markedObjectId?.maintenanceFolderId != null
                        ? item.markedObjectId.maintenanceFolderId
                        : null
                    }
                  }"
                  >{{ item.title }}{{ item.extension === 'xml' || item.extension === '' ? '' : '.' + item.extension }}</router-link
                >
              </td>
              <td class="pr-0" style="display: flex; align-items: center;" align="center">
                <label class="font-size-10" :class="baseTextStyle">
                  {{ item.dateTranslated }}
                </label>
              </td>
              <td class="pa-0" align="center">
                <v-menu 
                  v-if="hasOptions(item)" 
                  bottom left offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="action-menu">
                      <v-btn icon v-bind="attrs" v-on="on" :class="baseMenuStyle" style="display: flex; width: fit-content;">
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <v-list class="py-0">
                    <v-list-item
                    
                      @click="
                        if (item.extension === 'xml' && item.locked && item.objectId.version === 0) {
                          redirectTo({
                            name: 'editor',
                            params: { id: item.objectId.id },
                          })
                        } else {
                          redirectTo({
                            name: 'edit-document-view',
                            params: { id: item.objectId.id },
                          })
                        }
                      "
                    >
                      <v-list-item-title>
                        <span>{{ t('Edit') }}</span>
                      </v-list-item-title>
                    </v-list-item>
  
                    <v-list-item v-if="isTransferEditionTab && canTransferEdition([item])"
                          @click="
                            selectedDocuments = [item]
                            isTransferEditionDialogVisible = true; 
                          ">
                        <v-list-item-title>
                          <span>{{ t('LIB.DM.TransferEdition') }}</span>
                        </v-list-item-title>
                      </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <transfer-edition-dialog
      :is-dialog-visible="isTransferEditionDialogVisible"
      :items="selectedDocuments"
      @get-transfer-edition-data="getTransferEditionData($event)"
      @close-dialog="isTransferEditionDialogVisible = false"
      @transfer-edition="transferEdition(selectedDocuments, $event)"
    />
  </v-menu>
</template>

<script>
import LibDocumentManagementRs from 'lib-document-management-rs/src/lib-components/document-management-rs.vue'
import { mdiBriefcase, mdiChevronDown, mdiDotsVertical, mdiFilterVariant } from '@mdi/js'

import { i18n } from '@/plugins/i18n'
import { useUtils } from '@/plugins/i18n/utils'
import { ref, computed } from 'vue'
import Authentication from 'lib-security/src/lib-components/security.vue'

import { EventBus } from '@/plugins/event-bus'
import router from '@/router';

//Tranfer edition dialog
import TransferEditionDialog from "lib-document-management-vue/src/lib-components/dialogs/transfer-edition-dialog.vue";
import documentManagement from "lib-document-management-vue/src/lib-components/documentManagement";

export default {
  components: {
    TransferEditionDialog
  },
  setup() {

    const {
      transferEdition,
      documentList,
      canTransferEdition,
      getTransferEditionData,
    } = documentManagement()

    // i18n
    const { t } = useUtils()

    const defaultLang = computed(() => {
      return i18n.fallbackLocale
    })

    const lang = computed(() => {
      return i18n.locale
    })

    const isTransferEditionTab = computed(() => {
      return tabs.value[tab.value].id === 'edition' || tabs.value[tab.value].id == 'recents' ;
    })

    const tabs = computed(() => {
      return [
        {
          id: 'edition',
          title: t('InEdition'),
          perms: ['hermes-editor'],
        },
        {
          id: 'recents',
          title: t('Recents'),
          perms: [],
        },
        {
          id: 'revisions',
          title: t('Revisions'),
          perms: ['revision'], // TODO: put right permission when functionality is done
        },
      ]
    })

    const tableColumns = computed(() => {
      return [
        { text: t('Name'), value: 'title', class: 'pl-5' },
        { text: t('Date'), value: 'date' },
        {
          text: t(''),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    })

    const dates = ref([
      '2022-06-01T13:44:06.841+00:00',
      '2022-05-23T08:24:05.841+00:00',
      '2022-05-20T10:21:51.841+00:00',
      '2022-05-19T09:01:32.841+00:00',
    ])

    const dateOptions = ref({
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    })

    const userData = computed(() => {
      var tokenParsed = {};
      try {
        var tokenParsed = Authentication.methods.getTokenParsed();
        tokenParsed.roles = tokenParsed.realm_access.roles;
      } catch (error) {
        console.log(error);
      } finally {
        return tokenParsed;
      }
    });

    const contentListTable = computed(() => {
      var array = [];

      // Edition documents
      array[0] = [];

      editionDocuments.value.forEach(document => {
        var title = getTitleInList(document.displayTitles);
        document.title = title;
        if (document.lockedAt) {
          var date = new Date(document.lockedAt);
          var localeDate = date.toLocaleDateString(lang.value, dateOptions.value);
          document.date = date;
          document.dateTranslated = localeDate;
        }

        array[0].push(document);
      })

      // Recent documents
      array[1] = []

      allRecentDocumentsData.value.forEach(document => {
        if (document.objectId) {
          var title = getTitleInList(document.displayTitles);
          document.title = title;
          var date = new Date(document.visitedAt);
          var localeDate = date.toLocaleDateString(lang.value, dateOptions.value);
          document.date = date;
          document.dateTranslated = localeDate;
      
          array[1].push(document);
        }
      })

      return array;
    })

    const loading = ref(false)
    const options = ref({
      page: 1,
      itemsPerPage: 5,
      sortBy: ['date'],
      sortDesc: [true],
    })
    const initialTab = 1
    const tab = ref(initialTab)

    const perms = ref([])

    const isHovered = ref(false)
    const isOpen = ref(false)

    /*const textColor = computed (() => {
      return (isHovered.value || isOpen.value) ? '#8B8B8C' : '#8B8B8C'
    })*/

    function hoverItem() {
      isHovered.value = true
    }

    function unhoverItem() {
      isHovered.value = false
    }

    const baseTabStyle = ref('main-color font-weight-light cursor-pointer')
    const selectedTabStyle = ref('main-color font-weight-bold')
    const listTabStyles = ref(Array(tabs.value.length).fill(baseTabStyle.value))

    const baseTextStyle = ref('grey-color-1')
    const baseMenuStyle = ref('main-color')

    function getPermissions() {
      try {
        var tokenParsed = Authentication.methods.getTokenParsed()
        if (!tokenParsed) {
          return
        }
        perms.value = tokenParsed.realm_access.roles
      } catch (error) {
        console.log(error)
        console.log('Error status: ' + error.response.status)
      }
    }
    getPermissions()

    function updateTab(index) {
      tab.value = index
      updateTabStyle(index)
    }

    function updateTabStyle(index) {
      for (var i = 0; i < listTabStyles.value.length; i++) {
        listTabStyles.value.splice(i, 1, baseTabStyle.value)
      }
      listTabStyles.value.splice(index, 1, selectedTabStyle.value)
    }
    updateTabStyle(initialTab)

    function getTitleInList(titles, l = lang.value) {
      var base = titles[0].title
      for (var i = 0; i < titles.length; i++) {
        if (titles[i].locale === l && titles[i].title != '') {
          return titles[i].title
        }
        // Save defaultLang title and keep searching for the right title
        else if (titles[i].locale === defaultLang.value && titles[i].title != '') {
          base = titles[i].title
        }
      }
      return base
    }

    async function getDocumentPromise(id, addedAt) {
      try {
        var response = await LibDocumentManagementRs.methods.getDocumentData(id, false)
        var document = response.data
        if (addedAt != undefined) {
          document.visitedAt = addedAt
        }
        return document
      } catch (error) {
        console.log(error)
        return {}
      }
    }

    function processDocumentPermissions(documents) {

      return documents.map(doc => {

          let document = documentList.value.find(d => (doc.objectId.id == d.objectId.id && doc.objectId.version == d.objectId.version))

          if (document) {
            doc.allPermissionsLoaded = document.allPermissionsLoaded
            doc.allPermissions = document.permissions
          } else documentList.value.push(doc)

          return doc

      })
      
    }

    // Recent
    const allRecentDocumentsData = ref([])

    async function getRecents() {
      try {
        var response = await LibDocumentManagementRs.methods.getMarkedObjects('recent')
        var markedObjects = response.data
        var markedElements = []
        for (var markedObject of markedObjects) {
          if (markedObject['document'] != null) {
            //markedObject['document'].objectType = 'document'
            markedObject['document'].visitedAt = markedObject.addedAt;
            markedObject['document'].documentLink = markedObject.documentLink;
            markedObject['document'].markedObjectId = markedObject.markedObjectId;
            markedElements.push(markedObject['document']);
          }
        }
        allRecentDocumentsData.value = markedElements

        //Transfer edition data
        allRecentDocumentsData.value = processDocumentPermissions(allRecentDocumentsData.value) 

      } catch (error) {
        console.log(error)
      }
    }

    getRecents()

    EventBus.$on('update-recents', () => {
      getRecents()
    })

    // In edition

    const editionDocuments = ref([])

    async function getDocumentsInEditionOfUser() {
      try {
        var response = await LibDocumentManagementRs.methods.getDocumentsInEditionOfUser();
        editionDocuments.value = response.data;

        editionDocuments.value = processDocumentPermissions(editionDocuments.value) 
        
      } catch (error) {
        console.log(error)
      }
    }

    getDocumentsInEditionOfUser();

    EventBus.$on('update-documents-in-edition', () => {
      getDocumentsInEditionOfUser();
    })

    EventBus.$off('edition-transfered-top-menu')

    EventBus.$on('edition-transfered-top-menu', async () => {
        documentList.value = []
        await getDocumentsInEditionOfUser()
        await getRecents()
    })

    function redirectTo(to) {
      var route = router.app._route;
      if (to && (route.name != to.name || route.params.id != to.params.id)) {
        router.push(to);
      }
    }

    function hasOptions(item) {
      return item.maintenancePlan == undefined;
    }

    const isTransferEditionDialogVisible = ref(false);

    const selectedDocuments = ref([])

    return {
      t,
      tabs,
      tableColumns,
      contentListTable,
      loading,
      options,
      tab,
      perms,
      isHovered,
      isOpen,
      //textColor,
      hoverItem,
      unhoverItem,
      listTabStyles,
      baseTextStyle,
      baseMenuStyle,
      updateTab,
      redirectTo,
      icons: {
        mdiBriefcase,
        mdiChevronDown,
        mdiDotsVertical,
        mdiFilterVariant,
      },
      //Transfer edition dialog
      transferEdition,
      canTransferEdition,
      isTransferEditionDialogVisible,
      isTransferEditionTab,
      documentList,
      selectedDocuments,
      getTransferEditionData,
      hasOptions,
    }
  },
}
</script>

<style scoped>
/*button span {
  color: var(--textColor)
}*/
</style>