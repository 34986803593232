<template>
  <section id="settings-page">
  </section>
</template>

<script>
import settings from "./settings";

export default {
  setup() {

    const {
      watch,
      EventBus,
      i18n,
      router,
      lang,
      basePath,
      settingsItems,
    } = settings();

    function getDefaultBreadcrumb() {
      var array = [];
      array.push({
        text: i18n.t("Settings"),
      });
      EventBus.$emit("update-path-breadcrumb-default", basePath.value.concat(array));
    }

    function getPageTitle() {
      var pageTitle = i18n.t("Settings");
      EventBus.$emit("update-page-header-title", pageTitle);
    }

    function getPathBreadcrumb() {
      var array = [];
      EventBus.$emit("update-path-breadcrumb-path", array);
    }

    watch([lang, basePath,], () => {
      getDefaultBreadcrumb();
      getPageTitle();
      getPathBreadcrumb();
    }, {
      deep: true,
    });

    function init() {
      getDefaultBreadcrumb();
      getPageTitle();
      getPathBreadcrumb();
      if (settingsItems.value.length === 0) {
        router.replace({ 'name': 'not-authorized' });
      }
      for (var i = 0; i < settingsItems.value.length; i++) {
        var item = settingsItems.value[i];
        for (var j = 0; j < item.children.length; j++) {
          var initialRoute = item.children[j];
          if (initialRoute.to.name != '' && router.app._route.name != initialRoute.to) {
            router.replace(initialRoute.to)
            return;
          }
        }
      }
    }

    init();

    return {
    };
  },
};
</script>