<template>
  <div>
    <div v-for="(child, index) in item.children" :key="index">
      <v-divider v-if="depth > 1 || index > 0" />
      <v-list-group
        v-if="child.type === 'Folder'"
        :class="'vertical-nav-menu-group depth-' + depth"
        v-model="child.active"
      >
        <template #prependIcon>
          <router-link :to="child.to">
            <v-container style="display: inline-block">
              <v-badge
                v-if="child.children && child.children.length > 0"
                bottom
                left
                overlap
                :content="child.children.length"
                :style="'margin-left: ' + depth * 12 + 'px'"
              >
                <span class="material-symbols-outlined" style="line-height: inherit">
                  {{ child.icon }}
                </span>
              </v-badge>
              <span
                v-else
                class="material-symbols-outlined"
                :style="'margin-left: ' + depth * 12 + 'px'"
                style="line-height: inherit"
              >
                {{ child.icon }}
              </span>
            </v-container>
          </router-link>
        </template>
        <template #activator>
          <router-link
            :to="child.to"
            style="
              text-decoration: none;
              color: inherit;
              display: inline-block;
              width: 100%;
              height: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            <v-list-item-content
              :class="child.type != 'Folder' || child.children.length === 0 ? 'pr-4' : ''"
              style="height: 100%"
            >
              <v-list-item-title class="font-size-12 element-name">{{ child.title }}</v-list-item-title>
            </v-list-item-content>
          </router-link>
        </template>
        <template #appendIcon>
          <v-icon v-if="child.type != 'Folder' || child.children.length === 0"> </v-icon>
        </template>
        <document-management-tree-child :element="child" :depth="depth + 1" />
      </v-list-group>
      <v-list-item v-else :to="child.to" :class="'menu-document depth-' + depth">
        <v-container style="display: inline-block">
          <span
            class="material-symbols-outlined"
            :style="'margin-left: ' + depth * 12 + 'px'"
            style="line-height: inherit"
          >
            {{ child.icon }}
          </span>
        </v-container>
        <router-link
          :to="child.to"
          style="
            text-decoration: none;
            color: inherit;
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          <v-list-item-content class="pr-4">
            <v-list-item-title class="font-size-12 element-name">{{ child.title }}</v-list-item-title>
            <v-list-item-title class="font-size-10 element-version grey-color-1" v-if="child.version"
              >v.{{ child.version }}</v-list-item-title
            >
          </v-list-item-content>
        </router-link>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import settings from './settings'

export default {
  name: 'DocumentManagementTreeChild',

  props: {
    element: {
      type: Object,
    },
    depth: {
      type: Number,
    },
  },

  setup(props) {
    const {
      ref,
      computed,

      t,
    } = settings()

    const item = computed(() => {
      return props.element
    })

    return {
      t,
      item,
    }
  },
}
</script>
