<template>
  <v-menu
    offset-y
    nudge-bottom="10"
    min-width="210"
    content-class="user-profile-menu-content"
    v-model="isOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="button-flex button-type-1">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="px-0 align-center"
          text
          min-height="60"
          min-width="60"
          @mouseover="hoverButton()"
          @mouseleave="unhoverButton()"
        >
          <v-avatar
            v-if="clientDarkIsotype || clientLightIsotype"
            :aria-label="'User icon'"
            size="40px"
            tile
            class="primary--text"
            :class="$vuetify.breakpoint.lgAndUp ? 'ml-2' : ''"
          >
            <v-img :src="isDark ? clientDarkIsotype : clientLightIsotype" contain></v-img>
          </v-avatar>
          <span class="font-size-14 font-weight-regular ml-2" v-if="$vuetify.breakpoint.lgAndUp">{{ fullname }}</span>
          <v-icon class="grey-2-hover-orange mx-2" v-if="$vuetify.breakpoint.lgAndUp">{{ icons.mdiChevronDown }}</v-icon>
        </v-btn>
        <div>
          <v-divider class="main-divider divider-hover-orange" :style="{visibility: isHovered || isOpen ? 'visible' : 'hidden'}"/>
        </div>
      </div>
    </template>
    <v-list>
      <div class="pb-2 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            tile
            class="primary--text"
          >
            <v-img :src="isDark ? clientDarkIsotype : clientLightIsotype" contain></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3 me-3"
          style="vertical-align:middle"
        >
          <label class="grey-color-1 font-weight-semibold font-size-13 mb-n1">
            {{ fullname }}
          </label>
          <label class="grey-color-1 font-weight-light font-size-12 text-capitalize">
            <small>
              {{ t('LIB.CM.Level.' + role) }}
            </small>
          </label>
        </div>
      </div>

      <v-divider class="my-2 mx-5" v-if="permissions.includes('hermes-profile')"></v-divider>

      <!-- Profile -->
      <router-link
        v-if="permissions.includes('hermes-profile')"
        :to="{ name: 'user-management-profile' }"
        style="text-decoration: none"
      >
        <v-list-item
          class="pl-6 cursor-pointer button-type-1"
        >
          <v-list-item-icon class="me-2">
            <v-icon size="25" class="grey-hover-grey">
              {{ icons.mdiAccount }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-size-12 app-bar-user-list-text">{{ t('Profile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <v-divider
        v-if="(role != 'reader' && settingsItems?.length > 0) || permissions.includes('hermes-reports') || permissions.includes('hermes-subscriptions')"
        class="my-2 mx-5"
      ></v-divider>

      <!-- Settings -->
      <router-link
        v-if="role != 'reader' && settingsItems?.length > 0"
        :to="{ name: 'settings' }"
        style="text-decoration: none"
      >
        <v-list-item
          class="pl-6 cursor-pointer button-type-1"
        >
          <v-list-item-icon class="me-2">
            <v-icon size="25" class="grey-hover-grey">
              {{ icons.mdiCog }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-size-12 app-bar-user-list-text">{{ t('Settings') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <!-- Settings -->
      <!-- TODO -->
      <!--<v-list-item
        v-if="/*permissions.includes('hermes-reports')*/false"
        href=""
        class="pl-6 cursor-pointer button-type-1"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="25" class="grey-hover-grey">
            {{ icons.mdiChartBubble }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-12 app-bar-user-list-text">{{ t('Reports') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->

      <!-- FAQ -->
      <!-- TODO -->
      <!--<v-list-item
        v-if="/*permissions.includes('hermes-subscription')*/false"
        href=""
        class="pl-6 cursor-pointer button-type-1"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="25" class="grey-hover-grey">
            {{ icons.mdiAccount }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-12 app-bar-user-list-text">{{ t('Subscriptions') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->

      <v-divider class="my-2 mx-5"></v-divider>

      <!-- Logout -->
      <v-list-item href="" class="pl-6 cursor-pointer button-type-1" v-on:click="logout()">
        <v-list-item-icon class="me-2">
          <v-icon size="25" class="grey-hover-grey">
            {{ icons.mdiLogout }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-size-12 app-bar-user-list-text">{{ t('LogOut') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Authentication from 'lib-security/src/lib-components/security.vue'
import {
  mdiAccount,
  mdiCog,
  mdiLogout,
  mdiChevronDown,
  mdiChartBubble,
} from '@mdi/js'

import { useUtils } from '@/plugins/i18n/utils'
import { ref, computed } from 'vue'
import router from '@/router'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import useAppConfig from '@core/@app-config/useAppConfig'
import store from '@/store';
import settings from "./settings/settings";

export default {
  setup() {

    const {
      settingsItems,
    } = settings();

    // i18n
    const { t } = useUtils();
    const { route } = useRouter();

    const fullname = ref('UNDEFINED');
    const role = ref('UNDEFINED');
    const permissions = ref([]);

    const isHovered = ref(false);
    const isOpen = ref(false);

    function hoverButton() {
      isHovered.value = true;
    }

    function unhoverButton() {
      isHovered.value = false;
    }
    
    function getUserData() {
      try {
        var tokenParsed = Authentication.methods.getTokenParsed();
        if (!tokenParsed) {
          return;
        }
        fullname.value = tokenParsed.name;
        role.value = tokenParsed.role;
        permissions.value = tokenParsed.realm_access.roles;
      }
      catch (error) {
        console.log(error);
      }
    }
    
    function logout() {
      Authentication.methods.logout();
    }

    getUserData();

    const { isDark } = useAppConfig()

    const clientLightIsotype = computed(() => {
      return store.state.app.clientLogo.value.lightIsotype;
    })

    const clientDarkIsotype = computed(() => {
      return store.state.app.clientLogo.value.darkIsotype;
    })

    return {
      t,
      fullname,
      role,
      permissions,
      isHovered,
      isOpen,
      hoverButton,
      unhoverButton,
      logout,
      
      icons: {
        mdiAccount,
        mdiCog,
        mdiLogout,
        mdiChevronDown,
        mdiChartBubble,
      },
      
      isDark,
      clientLightIsotype,
      clientDarkIsotype,

      settingsItems,
    }
  },

}
</script>

<style lang="scss">
.user-profile-menu-content {
  border-radius: 10px;

  .v-list-item {
    min-height: 2.5rem !important;
  }

  .v-list-item::before {
    display: none;
  }
}
</style>
