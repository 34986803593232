<template>
  <div id="settings-menu">
    <v-divider />
    <v-list class="pa-0" expand>
      <v-list-item-group>
        <div v-for="(item, index) in settingsItems" :key="index">
          <v-list-group v-if="item.children" class="menu-group vertical-nav-menu-group" v-model="item.active">
            <template #prependIcon>
              <router-link :to="item.to">
                <div class="fill-div">
                  <span class="material-symbols-outlined">
                    {{ item.icon }}
                  </span>
                </div>
              </router-link>
            </template>
            <template #activator>
              <router-link :to="item.to" class="group-name h-full w-full">
                <v-list-item-content class="px-1">
                  <v-list-item-title class="font-size-14">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </router-link>
            </template>
            <template #appendIcon>
              <v-icon v-if="item.children && item.children.length === 0"> </v-icon>
            </template>
            <v-divider class="main-divider border-color-1" />
            <settings-menu-child :element="item" :depth="1" />
          </v-list-group>
          <v-list-item v-else :to="item.to" class="menu-item vertical-nav-menu-group pa-0 ma-0">
            <div class="item__header">
              <div class="item__header__prepend-icon">
                <div class="fill-div">
                  <span class="material-symbols-outlined">
                    {{ item.icon }}
                  </span>
                </div>
              </div>
              <div class="item-name">
                <v-list-item-content class="px-1">
                  <v-list-item-title class="font-size-14">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </div>
            </div>
          </v-list-item>
          <v-divider />
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import SettingsMenuChild from './settings-menu-child.vue'

import settings from './settings'

export default {
  components: {
    SettingsMenuChild,
  },

  setup() {
    const { t, settingsItems } = settings()

    return {
      t,
      settingsItems,
    }
  },
}
</script>
