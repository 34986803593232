import Vue from 'vue'
import { computed } from 'vue'
import VueRouter from 'vue-router'
import Authentication from 'lib-security/src/lib-components/security.vue'

import clientRouter from 'lib-client-management-vue/src/router'
import documentRouter from 'lib-document-management-vue/src/router'
import supportRouter from 'lib-hermes-support/src/router'
import userRouter from 'lib-user-management-vue/src/router'
import searchRouter from 'lib-search-vue/src/router'
import logRouter from 'lib-log-vue/src/router'
import editorRouter from 'lib-editor-vue/src/router'
import composerRouter from 'lib-composer-vue/src/router'
import sparePartRouter from 'lib-spare-part-management-vue/src/router'
import maintenancePlanRouter from 'lib-maintenance-plan-management-vue/src/router'
import standardSentenceRouter from 'lib-standard-sentence-management-vue/src/router'
import notificationRouter from 'lib-notification-vue/src/router'
import catalogRouter from 'lib-catalog-management-vue/src/router'
import SettingsPage from '@/components/settings/settings-page.vue'

Vue.use(VueRouter)

const roles = computed(() => {
  var array = []
  try {
    var tokenParsed = Authentication.methods.getTokenParsed()
    array = tokenParsed.realm_access.roles
  } catch (error) {
    console.log(error)
  } finally {
    return array
  }
})

const role = computed(() => {
  var userRole = []
  try {
    var tokenParsed = Authentication.methods.getTokenParsed()
    userRole = tokenParsed.role
  } catch (error) {
    console.log(error)
  } finally {
    return userRole
  }
})

const baseRoutes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/NotificationList.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: (to, from, next) => {
      if (roles.value.includes('hermes-notifications')) {
        next()
      } else {
        next('/not-authorized')
      }
    },
  },
  {
    path: '/notification/:notificationId',
    name: 'notification',
    component: () => import('@/views/NotificationView.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: (to, from, next) => {
      if (roles.value.includes('hermes-notifications')) {
        next()
      } else {
        next('/not-authorized')
      }
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPage,
    meta: {
      layout: 'settings',
    },
    beforeEnter: (to, from, next) => {
      if (role.value != 'reader') {
        next()
      } else {
        next('/not-authorized')
      }
    },
  },
  /*
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  */
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const routes = baseRoutes
  .concat(clientRouter.options.routes)
  .concat(documentRouter.options.routes)
  .concat(supportRouter.options.routes)
  .concat(userRouter.options.routes)
  .concat(searchRouter.options.routes)
  .concat(logRouter.options.routes)
  .concat(editorRouter.options.routes)
  .concat(composerRouter.options.routes)
  .concat(sparePartRouter.options.routes)
  .concat(standardSentenceRouter.options.routes)
  .concat(maintenancePlanRouter.options.routes)
  .concat(notificationRouter.options.routes)
  .concat(catalogRouter.options.routes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
