<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems" :isInEditor="isInEditor">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ toggleVerticalNavMenuActive }">

      <v-row no-gutters class="d-flex align-center">
        <v-col id="breadcrumb-div">
          <v-row no-gutters class="d-flex align-center">
            <v-col class="d-flex align-center pr-4 my-2" style="min-width: 100px;">
              <path-breadcrumb />
            </v-col>
          </v-row>
        </v-col>
        <v-col id="app-bar-div">
          <v-row no-gutters class="d-flex align-center">
            <!-- Left Content: Menu button -->
            <div id="menu-logo-div" class="my-2">
              <div class="d-flex align-center">
                <v-icon
                  v-if="$vuetify.breakpoint.mdAndDown"
                  class="me-3"
                  @click="toggleVerticalNavMenuActive"
                  size="30"
                >
                  {{ icons.mdiMenu }}
                </v-icon>
                <router-link
                  to="/"
                  class="d-flex align-center text-decoration-none"
                  v-if="$vuetify.breakpoint.mdAndDown"
                >
                  <v-slide-x-transition>
                    <v-img
                      :src="isDark ? clientDarkLogo : clientLightLogo"
                      max-width="50px"
                      alt="logo"
                      contain
                      eager
                      class="app-logo me-3"
                      v-show="!menuIsVerticalNavMini"
                    ></v-img>
                  </v-slide-x-transition>
                </router-link>
              </div>
            </div>
            <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
            <v-col id="app-bar-menu-div" class="d-flex align-center" style="justify-content: end;">
              <app-bar-activity v-if="!['reader', 'collaborator'].includes(userData.role)"></app-bar-activity>
              <app-bar-support></app-bar-support>
              <app-bar-admin></app-bar-admin>
              <app-bar-notifications v-if="userData.roles.includes('hermes-notifications')"></app-bar-notifications>
              <app-bar-i18n></app-bar-i18n>
              <app-bar-theme-switcher v-if="userData.roles.includes('hermes-can-switch-theme')"></app-bar-theme-switcher>
              <app-bar-user-menu></app-bar-user-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://geminys.com"
          class="text-decoration-none"
        >Geminys</a><span class="d-none d-md-inline">, {{ t('AllRightsReserved') }}</span></span>
        <div class="align-center d-none d-md-flex">
          <span>GEMSYS version {{ appVersion }}</span>
        </div>
      </div>
    </template>

    <!--<template #v-app-content>
      <app-customizer class="d-none d-md-block"></app-customizer>
    </template>-->
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
//import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import navMenuItems from '@/navigation/vertical'

// App Bar Components
import AppBarActivity from '@core/layouts/components/app-bar/AppBarActivity.vue'
import AppBarSupport from '@core/layouts/components/app-bar/AppBarSupport.vue'
import AppBarAdmin from '@core/layouts/components/app-bar/AppBarAdmin.vue'
import AppBarNotifications from '@core/layouts/components/app-bar/AppBarNotifications.vue'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'

import { mdiMenu } from '@mdi/js'

import { useUtils } from '@/plugins/i18n/utils'

import useAppConfig from '@core/@app-config/useAppConfig'
import themeConfig from '@themeConfig'

import store from '@/store'

import { computed, } from "vue";
import PathBreadcrumb from "lib-hermes-components/src/lib-components/path-breadcrumb/path-breadcrumb.vue";
import Authentication from 'lib-security/src/lib-components/security.vue'

export default {
  components: {
    LayoutContentVerticalNav,
    //AppCustomizer,

    // App Bar Components
    AppBarActivity,
    AppBarSupport,
    AppBarAdmin,
    AppBarNotifications,
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    
    PathBreadcrumb,
  },
  props: {
    isInEditor: {
      type: Boolean,
      required: true,
    }
  },
  setup() {

    // i18n
    const { t } = useUtils()

    const appVersion = process.env.VUE_APP_VERSION

    const { menuIsVerticalNavMini, isDark } = useAppConfig()

    const clientLightLogo = computed(() => {
      return store.state.app.clientLogo.value.lightLogo;
    })

    const clientDarkLogo = computed(() => {
      return store.state.app.clientLogo.value.darkLogo;
    })

    const userData = computed(() => {
      var tokenParsed = {};
      try {
        var tokenParsed = Authentication.methods.getTokenParsed();
        tokenParsed.roles = tokenParsed.realm_access.roles;
      } catch (error) {
        console.log(error);
      } finally {
        return tokenParsed;
      }
    });

    return {
      t,

      navMenuItems,

      appVersion,
      
      menuIsVerticalNavMini,
      isDark,
      clientLightLogo,
      clientDarkLogo,

      userData,

      icons: {
        mdiMenu,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-grow: 1;
  position: relative;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
    /* Change the order style to change the order of the items. */
    #breadcrumb-div {
      order: 2;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
    #app-bar-div {
      order: 1;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
    #breadcrumb-spacer {
      display: none;
    }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
    #menu-logo-div {
      order: 2;
      width: 100%;
      max-width: 100%;
    }
    #app-bar-menu-div {
      order: 1;
      width: 100%;
      max-width: 100%;
    }
}
</style>
