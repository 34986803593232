<template>
  <div class="vertical-nav-header d-flex align-center justify-space-between px-5 py-4">
    <router-link
      to="/"
      class="d-flex align-center text-decoration-none"
    >
      <v-slide-x-transition>
        <v-img
          :src="isDark ? clientDarkIsotype : clientLightIsotype"
          height="50px"
          max-width="50px"
          alt="logo"
          contain
          eager
          class="app-logo"
          v-if="menuIsVerticalNavMini"
        ></v-img>
      </v-slide-x-transition>
      <v-slide-x-transition>
        <v-img
          :src="isDark ? clientDarkLogo : clientLightLogo"
          height="50px"
          max-width="260px"
          alt="logo"
          contain
          position="start start"
          eager
          class="app-logo"
          v-if="!(menuIsVerticalNavMini)"
        ></v-img>
      </v-slide-x-transition>
    </router-link>
  </div>
</template>

<script>
import { mdiRadioboxBlank, mdiRecordCircleOutline, mdiClose } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import themeConfig from '@themeConfig'
import store from '@/store'
import { computed, } from 'vue';

export default {
  setup() {
    const { menuIsVerticalNavMini, isDark } = useAppConfig()

    const clientLightIsotype = computed(() => {
      return store.state.app.clientLogo.value.lightIsotype;
    })

    const clientDarkIsotype = computed(() => {
      return store.state.app.clientLogo.value.darkIsotype;
    })

    const clientLightLogo = computed(() => {
      return store.state.app.clientLogo.value.lightLogo;
    })

    const clientDarkLogo = computed(() => {
      return store.state.app.clientLogo.value.darkLogo;
    })

    return {
      menuIsVerticalNavMini,
      appName: themeConfig.app.name,
      appLogoBlack: themeConfig.app.logoBlack,
      appLogoWhite: themeConfig.app.logoWhite,
      clientLightIsotype,
      clientDarkIsotype,
      clientLightLogo,
      clientDarkLogo,
      isDark,

      // Icons
      icons: {
        mdiClose,
        mdiRadioboxBlank,
        mdiRecordCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}
</style>
