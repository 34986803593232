import Vue from 'vue'
import VueI18n from 'vue-i18n'

const defaultLang = 'en-GB'

const messages = require('@/plugins/i18n/locales/' + defaultLang + '.json')
const hermesComponentsMessages = require('lib-hermes-components/src/plugins/i18n/locales/' + defaultLang + '.json')
const documentManagementMessages = require('lib-document-management-vue/src/plugins/i18n/locales/' +
  defaultLang +
  '.json')
const clientManagementMessages = require('lib-client-management-vue/src/plugins/i18n/locales/' + defaultLang + '.json')
const hermesSupportMessages = require('lib-hermes-support/src/plugins/i18n/locales/' + defaultLang + '.json')
const userManagementMessages = require('lib-user-management-vue/src/plugins/i18n/locales/' + defaultLang + '.json')
const searchMessages = require('lib-search-vue/src/plugins/i18n/locales/' + defaultLang + '.json')
const logMessages = require('lib-log-vue/src/plugins/i18n/locales/' + defaultLang + '.json')
const editorMessages = require('lib-editor-vue/src/plugins/i18n/locales/' + defaultLang + '.json')
const composerMessages = require('lib-composer-vue/src/plugins/i18n/locales/' + defaultLang + '.json')
const sparePartMessages = require('lib-spare-part-management-vue/src/plugins/i18n/locales/' + defaultLang + '.json')
const standardSentenceMessages = require('lib-standard-sentence-management-vue/src/plugins/i18n/locales/' + defaultLang +'.json')
const maintenancePlanManagement = require('lib-maintenance-plan-management-vue/src/plugins/i18n/locales/' + defaultLang + '.json')
const notificationMessages = require('lib-notification-vue/src/plugins/i18n/locales/' + defaultLang + '.json')
const catalogManagementMessages = require('lib-catalog-management-vue/src/plugins/i18n/locales/' + defaultLang + '.json')

Vue.use(VueI18n)

// https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
/*
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.js$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  console.log(messages);
  return messages;
}

loadLocaleMessages();
*/

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || defaultLang, // set locale
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || defaultLang,
  // Quitar warnings cuando no encuentre traducciones:
  //silentTranslationWarn: process.env.NODE_ENV === 'development'
  //silentTranslationWarn: true
})

i18n.setLocaleMessage(defaultLang, {
  ...messages,
  ...hermesComponentsMessages,
  ...documentManagementMessages,
  ...clientManagementMessages,
  ...hermesSupportMessages,
  ...userManagementMessages,
  ...searchMessages,
  ...logMessages,
  ...editorMessages,
  ...composerMessages,
  ...sparePartMessages,
  ...standardSentenceMessages,
  ...maintenancePlanManagement,
  ...notificationMessages,
  ...catalogManagementMessages,
}) // set locale messages

const loadedLanguages = [defaultLang] // our default language that is preloaded

function setI18nLanguage(lang = defaultLang) {
  i18n.locale = lang

  return lang
}

export function loadLanguageAsync(lang = defaultLang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  /* eslint-disable prefer-template */
  var returnLang = setLanguage(lang)
  loadedLanguages.push(lang)
  return returnLang
  /* eslint-enable */
}

export function setLanguage(lang = defaultLang) {
  var localeMessages = {}
  var localeHermesComponentsMessages = {}
  var localeDocumentManagementMessages = {}
  var localeClientManagementMessages = {}
  var localeHermesSupportMessages = {}
  var localeUserManagementMessages = {}
  var localeSearchMessages = {}
  var localeLogMessages = {}
  var localeEditorMessages = {}
  var localeComposerMessages = {}
  var localeSparePartMessages = {}
  var localeStandardSentenceMessages = {}
  var localeMaintenancePlanMessages = {}
  var localeNotificationMessages = {}
  var localeCatalogManagementMessages = {}
  // REQUIRE DOES NOT WORK IN LOOP
  /*var i18nSourceArray = [
    '@/',
    'lib-hermes-components/src/',
    'lib-document-management-vue/src/',
    'lib-client-management-vue/src/',
    'lib-hermes-support/src/',
    'lib-user-management-vue/src/',
    'lib-search-vue/src/',
    'lib-log-vue/src/',
  ];
  var i18nDirectory = 'plugins/i18n/locales/' + lang + '.json';
  for (var i = 0; i < i18nSourceArray.length; i++) {
    try {
      const i18nString = i18nSourceArray[i] + i18nDirectory;
      localeMessages = require(i18nString);
      i18n.setLocaleMessage(lang, {
        ...localeMessages,
      });
    } catch (error) {
      console.log(error);
    }
  }*/
  try {
    localeMessages = require('@/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeHermesComponentsMessages = require('lib-hermes-components/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeDocumentManagementMessages = require('lib-document-management-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeClientManagementMessages = require('lib-client-management-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeHermesSupportMessages = require('lib-hermes-support/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeUserManagementMessages = require('lib-user-management-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeSearchMessages = require('lib-search-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeLogMessages = require('lib-log-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeEditorMessages = require('lib-editor-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeComposerMessages = require('lib-composer-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeSparePartMessages = require('lib-spare-part-management-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeStandardSentenceMessages = require('lib-standard-sentence-management-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeMaintenancePlanMessages = require('lib-maintenance-plan-management-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeNotificationMessages = require('lib-notification-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }
  try {
    localeCatalogManagementMessages = require('lib-catalog-management-vue/src/plugins/i18n/locales/' + lang + '.json')
  } catch (error) {
    //console.log(error);
  }

  i18n.setLocaleMessage(lang, {
    ...localeMessages,
    ...localeHermesComponentsMessages,
    ...localeDocumentManagementMessages,
    ...localeClientManagementMessages,
    ...localeHermesSupportMessages,
    ...localeUserManagementMessages,
    ...localeSearchMessages,
    ...localeLogMessages,
    ...localeEditorMessages,
    ...localeComposerMessages,
    ...localeSparePartMessages,
    ...localeStandardSentenceMessages,
    ...localeMaintenancePlanMessages,
    ...localeNotificationMessages,
    ...localeCatalogManagementMessages,
  })
  return setI18nLanguage(lang)
}
