<template>
  <v-speed-dial
    id="app-bar-locale"
    class="locale-speed-dial"
    direction="bottom"
    v-model="isOpen"
    open-on-hover
    transition="scale-transition"
    v-if="locales && locales.length > 1"
  >
    <!-- Activator -->
    <template #activator>
      <v-btn
        v-model="isOpen"
        fab
        icon
        small
        class="d-flex align-center ma-3"
        @click.stop
        :aria-label="'Language'"
      >
        <span>{{ locales && locales.length > 0 ? locales.find(l => l.id === $i18n.locale).localeUpperCase : '' }}</span>
      </v-btn>
    </template>

    <!-- Options List -->
    <!-- :disabled="locale.locale === $i18n.locale" -->
    <v-flex
      v-for="locale in locales"
      :key="locale.id"
      class="locale-button-hover-flex"
    >
      <v-btn
        fab
        icon
        small
        class="mb-0 locale-button-base"
        :class="locale.id === $i18n.locale ? 'locale-button-selected' : ''"
        :value="locale.id"
        @click.stop="updateActiveLocale(locale.id)"
      >
        {{ locale.localeUpperCase }}
      </v-btn>
      <div>
        <v-divider class="main-divider border-color-1 mx-2 mt-1"/>
      </div>
    </v-flex>
  </v-speed-dial>
</template>

<script>
import { i18n, loadLanguageAsync, } from '@/plugins/i18n'
import useAppConfig from '@core/@app-config/useAppConfig'

import { ref, computed } from 'vue'
import store from '@/store';

export default {
  setup() {
    const { isRtl } = useAppConfig()

    const locales = computed(() => {
      return store.state.app.appLanguages.value;
    })

    const updateActiveLocale = locale => {
      // Set to RTL mode if locale is arabic
      isRtl.value = locale === 'ar'
      
      loadLanguageAsync(locale)
    }
    const isOpen = ref(false)

    return {
      locales,
      updateActiveLocale,
      isOpen,
    }
  },
}
</script>

<style lang="scss">

  /*.locale-btn.theme--dark {
    background-color: rgba(map-deep-get($material-dark, 'cards'), 1);
  }*/

</style>