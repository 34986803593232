<template>
  <div>
    <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`" :isInEditor="isInEditor">
      <transition :name="appRouteTransition" mode="out-in" appear>
        <router-view :key="$route.path"></router-view>
      </transition>
      <async-notification-snackbar
        name="download"
        :message="t('Downloading')"
        :error-message="t('ErrorDownloadingFile')"
      />
      <notification-card-snackbar
        top
        right
        class="app-notification-card-snackbar"
      />
      <editor-context-menu
        element-id="editorContextMenu"
        ref="vueSimpleContextMenu"
        @context-menu-option-clicked="contextMenuOptionClicked"
      />
    </component>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, computed, } from 'vue'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutAppBarVerticalNav from '@/layouts/variants/app-bar/vertical-nav/LayoutAppBarVerticalNav.vue'
import LayoutEditorVerticalNav from '@/layouts/variants/editor/vertical-nav/LayoutEditorVerticalNav.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'

// Snackbar notification
import AsyncNotificationSnackbar from 'lib-hermes-components/src/lib-components/notification-snackbar/async-notification-snackbar.vue'
import NotificationCardSnackbar from 'lib-hermes-components/src/lib-components/notification-card/notification-card-snackbar.vue'

// i18n
import { useUtils } from '@/plugins/i18n/utils'

import EditorContextMenu from 'lib-editor-vue/src/lib-components/context-menu/EditorContextMenu';
import { EventBus } from '@/plugins/event-bus';

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    LayoutAppBarVerticalNav,
    LayoutEditorVerticalNav,
    AsyncNotificationSnackbar,
    NotificationCardSnackbar,
    EditorContextMenu,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()
    const { t } = useUtils()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content' || route.value.meta.layout === 'settings' || route.value.meta.layout === 'editor')
        return `layout-content-${appContentLayoutNav.value}-nav`
      if (route.value.meta.layout === 'app-bar') return `layout-app-bar-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    // Context menu

    const vueSimpleContextMenu = ref({});
    const editorRef = ref(null);
    const itemRef = ref(null);

    EventBus.$on('editor-right-click', (event, editor, item, document, latestDocumentVersion) => {
      //vueSimpleContextMenu.value.hideContextMenu()
      editorRef.value = editor;
      itemRef.value = item;
      vueSimpleContextMenu.value.showMenu(event, editor, item, document, latestDocumentVersion)
    })

    async function contextMenuOptionClicked (event) {
      event.option.action(editorRef.value, itemRef.value);
    }

    const isInEditor = computed(() => {
      return route.value.meta.layout === 'editor';
    })

    return {
      t,
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      vueSimpleContextMenu,
      contextMenuOptionClicked,
      isInEditor,
    }
  },
}
</script>
<style lang="scss">
.app-notification-card-snackbar {
  margin-top: 82px;
  margin-right: 1.5rem;
  .v-snack__wrapper {
    margin: 0px;
  }
}
</style>